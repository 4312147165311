import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import './App.css';
import Sidebar from './component/common/Sidebar';
import { CommonContextProvider } from './context/CommonContext';
import Pageloader from './component/common/Pageloader';
import CustomTheme from './component/common/CustomTheme';
import Merchant from './component/merchant';
import PickupView from './component/pickup';
import PickupPointsView from './component/pickupPoints';
import ShipmentsView from './component/shipments';
import RTOView from './component/rto';
import BranchView from './component/branches';
import UsersView from './component/users';
import DownloadView from './component/download';
import TrackingView from './component/trackingQuote';
import WeightScreenView from './component/weight';
import FreightView from './component/freight';
import InvoiceView from './component/invoice';
import ServiceabilityView from './component/serviceability';
import 'rsuite/dist/rsuite.css';
import ScanView from './component/scanStatus';
import NotFound from './context/NotFound ';
import MerchantSignIn from './component/common/MerchantSignIn';
import CryptoJS from 'crypto-js';
import SiteSetting from './component/siteSetting';
import PricingView from './component/pricing';
import Remittance from './component/remittance';
import Ndr from './component/ndr';
import Couriers from './component/couriers';
import Dashboard from './component/dashboard';
import Channel from './component/channel';


const AdminSignIn = React.lazy(() => import('./component/common/AdminSignIn'));
const CreateOrder = React.lazy(() => import('./component/shipments/CreateOrder'));
const OrderDetails = React.lazy(() => import('./component/shipments/OrderDetails'));
const PriceCalculator = React.lazy(() => import('./component/freight/PriceCalculator'));

// Decrypt function
const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const secretKey = 'app-secret';
function App() {
  let location = useLocation();
  const storedUserData = localStorage.getItem('userApp');
  const [user, setUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userPermission, setUserPermission] = useState(null);
  useEffect(() => {
    if (storedUserData) {
      const parsedUser = decryptData(storedUserData, secretKey);
      // setUser(user);
      if (parsedUser?.permissions) {
        setUserPermission(JSON.parse(parsedUser?.permissions))
      }
      setUser(parsedUser);
      if (parsedUser?.permissions) {
        setUserPermission(JSON.parse(parsedUser?.permissions))
      }
      setUser(parsedUser);
    }
  }, [storedUserData]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <CommonContextProvider>
      <ThemeProvider theme={CustomTheme}>
        <div className="App">
          {location.pathname !== '/admin' && location.pathname !== '/login' &&
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
          }
          <Suspense fallback={<Pageloader />}>
            <Routes>
              {user &&
                <Route path='*' element={<NotFound />} />
              }
              {user?.user_type !== "Merchant" ?
                <>
                  {(user) && (user?.id === 1 || userPermission?.Merchants?.add || userPermission?.Merchants?.list) &&
                    <Route exact path="/merchant" element={<Merchant />} />
                  }
                  {(user) && (user?.id === 1 || userPermission?.Branches?.add || userPermission?.Branches?.list) &&
                    <Route exact path="/branches" element={<BranchView />} />
                  }
                  {(user) && (user?.id === 1) &&
                    <Route exact path="/users" element={<UsersView />} />
                  }
                  {(user) && (user?.id === 1 || userPermission?.Tracking_Scans?.add || userPermission?.Tracking_Scans?.list) &&
                    <Route exact path="/scanStatus" element={<ScanView />} />
                  }
                  <Route path="/channel" element={<Channel />} />
                  <Route path="/merchant-details" element={<SiteSetting />} />
                  <Route path="/merchants/pricing" element={<PricingView />} />
                  <Route path="/couriers" element={<Couriers />} />
                </>
                :
                <>
                  <Route path="/siteSetting" element={<SiteSetting />} />
                </>
              }
              {(user) && (user?.id === 1 || userPermission?.NDR?.add || userPermission?.NDR?.list || userPermission?.NDR?.import || userPermission?.NDR?.export || user?.user_type === "Merchant") &&
                <Route path="/ndr" element={<Ndr />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Remittance?.add || userPermission?.Remittance?.list || userPermission?.Remittance?.import || userPermission?.NDR?.export || user?.user_type === "Merchant") &&
                <Route path="/remittance" element={<Remittance />} />
              }
              <Route exact path="/admin" element={<AdminSignIn />} />
              <Route exact path="/login" element={<MerchantSignIn />} />
              {(user) && (user?.id === 1 || userPermission?.Dashboard?.view || user?.user_type === "Merchant") &&
                <Route exact path="/" element={<Dashboard />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Orders?.add || userPermission?.Orders?.list || userPermission?.Orders?.upload || userPermission?.Orders?.upload_status || user?.user_type === "Merchant") &&
                <Route exact path="/orders" element={<ShipmentsView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Orders?.add || user?.user_type === "Merchant") &&
                <Route path="/orders/:createOrder" element={<CreateOrder />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Orders?.list || user?.user_type === "Merchant") &&
                <Route path="/orders/details" element={<OrderDetails />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Merchant_Warehouse?.add || userPermission?.Merchant_Warehouse?.list || user?.user_type === "Merchant") &&
                <Route exact path="/warehouse" element={<PickupPointsView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Pickup_Requests?.add || userPermission?.Pickup_Requests?.list || user?.user_type === "Merchant") &&
                <Route exact path="/pickupRequest" element={<PickupView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.RTO_Requests?.add || userPermission?.RTO_Requests?.list || user?.user_type === "Merchant") &&
                <Route exact path="/rtoRequest" element={<RTOView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Downloads?.list || user?.user_type === "Merchant") &&
                <Route exact path="/download" element={<DownloadView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Tracking_Codes?.add || userPermission?.Tracking_Codes?.list) &&
                <Route exact path="/tracking" element={<TrackingView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Serviceability?.import || userPermission?.Serviceability?.list || user?.user_type === "Merchant") &&
                <Route exact path="/serviceability" element={<ServiceabilityView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Weight?.import || userPermission?.Weight?.list || user?.user_type === "Merchant") &&
                <Route exact path="/weight" element={<WeightScreenView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Freight?.import || userPermission?.Freight?.list || user?.user_type === "Merchant") &&
                <Route exact path="/freight" element={<FreightView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Invoice?.add || userPermission?.Invoice?.import || userPermission?.Invoice?.list || user?.user_type === "Merchant") &&
                <Route exact path="/invoice" element={<InvoiceView />} />
              }
              <Route path="/price-calulator" element={<PriceCalculator />} />
            </Routes>
          </Suspense>
        </div>
      </ThemeProvider>
    </CommonContextProvider>
  );
}

export default App;
