import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';

export const DownloadContext = createContext();

export function DownloadContextProvider({ children }) {
    const { user } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allDownload, setAllDownload] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };

    const onLoadApi = () => {
        const url = user?.user_type === "Merchant" ? '/merchant/downloads' : '/admin/downloads';
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        } else {
            postReq = { admin_id: user?.id }
        }
        setFirstLoading(false);
        commonApi.post(url, postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllDownload(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allDownload,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllDownload,
        setViewData,
        setFirstLoading,
        setIsLoading,
    };

    // Render the provider with children
    return <DownloadContext.Provider value={contextValue}>{children}</DownloadContext.Provider>;
}
